// Bootstrap

$brand-primary: #c66;

@import "node_modules/bootstrap/scss/bootstrap";


// Variables

$retina-2x: "(-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-device-pixel-ratio: 2), (min-resolution: 2dppx), (min-resolution: 192dpi)";


// Styles

body {
    font-family: "proxima-nova-soft", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.srr-header {
    padding: 0 1rem;
    color: #fff;
    background: {
        color: $link-hover-color;
        image: url(/img/rose.svg);
        position: 50% 100%;
        repeat: no-repeat;
        size: 3rem;
    }
    @include clearfix();
    @media (min-width: 720px) {
        padding-left: 3.5rem;
        background: {
            position: 1rem 0;
        }
    }

    a {
        color: #fff;
    }
}

.srr-nameplate {
    display: none;
    @media (min-width: 720px) {
        display: block;
        float: left;
    }

    a {
        display: block;
        padding: 1rem;
        line-height: 1;
    }
}

.srr-nav {
    .srr-nav-toggle {
        @media (min-width: 720px) {
            display: none;
        }
    }

    .srr-nav-toggle-menu {
        display: none;
        padding: 1rem;
        line-height: 1;
        text-indent: -9999px;
        background: {
            image: url(/img/menu.svg);
            position: 0.875rem 50%;
            repeat: no-repeat;
            size: 1.25rem 1rem;
        }
    }

    .collapsed .srr-nav-toggle-menu {
        display: block;
    }

    .srr-nav-toggle-close {
        display: block;
        padding: 1rem;
        line-height: 1;
        text-indent: -9999px;
        background: {
            image: url(/img/close.svg);
            position: 1rem 50%;
            repeat: no-repeat;
            size: 1rem;
        }
    }

    .collapsed .srr-nav-toggle-close {
        display: none;
    }

    .collapse {
        @media (min-width: 720px) {
            display: block;
            visibility: visible;
        }
    }

    .srr-nav-list {
        @include clearfix();
    }

    .collapsing .srr-nav-list,
    .collapse.show .srr-nav-list {
        @media (max-width: 719px) {
          padding-bottom: 3rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        @media (min-width: 720px) {
            float: right;
            margin-bottom: 0;
            @include clearfix();
        }
    }

    li {
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        @media (min-width: 720px) {
            float: left;
            border-bottom-width: 0;
        }

        &:first-child {
            border-top: 2px solid rgba(255, 255, 255, 0.5);
            @media (min-width: 720px) {
                border-top-width: 0;
            }
        }

        &:last-child {
            border-bottom: 2px solid rgba(255, 255, 255, 0.5);
            @media (min-width: 720px) {
                border-bottom-width: 0;
            }
        }

        &.srr-nav-home {
            @media (min-width: 720px) {
                display: none;
            }
        }

        a, span {
            display: block;
            padding: 1rem;
            line-height: 1;
        }

        span {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

.srr-body {
    margin: 4rem 0;

    ol ul {
      margin-bottom: 1rem;
    }
}

.srr-hero {
    margin-bottom: 4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: {
        color: rgba(0, 0, 0, 0.05);
        image: url(/img/pages/56ee5d26921c2.jpg);
        position: 50% 50%;
        size: cover;
    }
}

.srr-intro {
    padding: 2.5rem 0;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1;
    text-align: center;
    text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    @media (min-width: 375px) {
        font-size: 1.5rem;
    }
    @media (min-width: 414px) {
        font-size: 1.6rem;
    }
    @media (min-width: 34em) {
        padding: 2.75rem 0;
        font-size: 2rem;
    }
    @media (min-width: 48em) {
        padding: 3rem 0;
        font-size: 2.5rem;
    }
    @media (min-width: 62em) {
        padding: 3.25rem 0;
        font-size: 3rem;
    }
    @media (min-width: 75em) {
        padding: 3.5rem 0;
        font-size: 3.5rem;
    }

    span {
        display: block;
    }
}

.srr-breadcrumb {
    margin-bottom: 2rem;
}

.srr-button-list {
    .srr-button-list-item {
        margin: {
            top: 1rem;
            bottom: 1rem;
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.srr-list {
    margin-bottom: 2rem;

    @media (min-width: 48em) {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .srr-list-header h1 {
        margin-bottom: 1rem;
        font-weight: bold;
        font-size: 1rem;
        text-transform: uppercase;
    }
    .srr-list-subhead {
        padding: 2rem 0 1rem;
        font-weight: bold;
        text-transform: uppercase;
        &:first-child {
            padding-top: 0;
        }
    }
    .srr-list-item {
        border-bottom: 1px solid #f2f2f2;
        padding: 1rem;
        &:first-child {
            border-top: 1px solid #f2f2f2;
        }
    }
    .srr-list-footer {
        margin-top: 1rem;
    }

    &.srr-list-sm {
        .srr-list-item {
            padding: 0.5rem 1rem;
        }
        .srr-list-footer {
            margin-top: 0.5rem;
        }
    }

    &.srr-list-interests {
        .srr-list-item {
            border-bottom-color: #4d4d4d;
            padding: 0;

            a {
                display: block;
                padding: 0.5rem 2.75rem;
                color: #fff;
                background: {
                    color: #333;
                    position: 1rem 50%;
                    size: 1.25rem 1.25rem;
                    repeat: no-repeat;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $link-hover-color;
                }
            }

            &:first-child {
                border-top-width: 0;

                a {
                    border-top-left-radius: 0.25rem;
                    border-top-right-radius: 0.25rem;
                }
            }

            &:last-child {
                border-bottom-width: 0;

                a {
                    border-bottom-left-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
                }
            }
        }
        .srr-list-footer {
            margin-top: 0.5rem;
        }
    }
}

@each $interest in food-and-drink, family-friendly, date-night, music, outdoor-adventures {
    .srr-interest-#{$interest} a {
        background-image: url(/img/interests/#{$interest}.svg);
    }
}

.srr-btn-default {
    @include button-variant(#fff, $link-color, $link-hover-color);
}

.srr-btn-secondary {
    @include button-variant(#fff, #999, #999);
}

.srr-more {
    text-align: center;

    .srr-list-sm &,
    .srr-list-interests & {
        font-size: 0.875rem;
        text-align: right;
        a {
            color: #666;
        }
    }
}

.srr-module {
    margin: 2rem 0;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.srr-map {
    height: 10rem;
}

.srr-photo {
    height: 10rem;
    background: {
        color: #000;
        position: 50% 50%;
        size: cover;
    }
}

.srr-sidebar {
    margin-bottom: 2rem;
}

.srr-name {
    margin: 2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0 0.5rem;
}

.srr-xevent-title {
    margin-top: 2rem;

    h1 {
        margin: 0;
    }
}

.srr-xevent-location {
    margin-top: 0.25rem;
    color: #666;
    line-height: 1.375;
}

.srr-xevent-actions {
    @include clearfix();

    margin-top: 1rem;
    margin-bottom: 2rem;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    padding: 1rem 0;

    .srr-links,
    .srr-button-list-item {
        display: block;
        float: left;
    }

    .srr-links {
        margin-bottom: 0;
    }

    .srr-button-list-item {
        margin-right: 0.875rem;
    }
}

.srr-xevent-description {
    margin-bottom: 2rem;
}

.srr-xevent-adminmenu {
    float: right;
}

.srr-event {
    position: relative;
}

.srr-event-details {
    margin-right: 2rem;
}

.srr-like-button {
    .srr-list-item & {
        position: absolute;
        top: 0;
        right: 0;
        width: 1rem;
        height: 1rem;
        margin: 0.25rem 0;

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            line-height: 1;
            text-indent: -9999px;
            background: {
                image: url(/img/heart-empty.svg);
                repeat: no-repeat;
            }
        }

        &.srr-status-liked a {
            background: {
                image: url(/img/heart-full.svg);
            }
        }
    }

    .srr-button-list &,
    .srr-xevent-actions & {
        a {
            background: {
                image: url(/img/heart-empty.svg);
                position: 0.5rem;
                repeat: no-repeat;
                size: 1.25rem;
            }
        }

        &.srr-status-liked a {
            background: {
                image: url(/img/heart-full.svg);
            }
        }
    }

    .srr-xevent-actions & a {
        padding-left: 2.25rem;
    }
}

.srr-details {
    margin-bottom: 2rem;
}

.srr-links {
    margin-bottom: 2rem;
    @include clearfix();

    .srr-xevent-actions & {
        margin-right: 0.875rem;
    }

    .srr-link {
        display: block;
        float: left;
        margin-right: 0.875rem;
        &:last-child {
            margin-right: 0;
        }
    }

    .srr-link-icon {
        width: 2.375rem;
        height: 2.375rem;

        a {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 0.25rem;
            line-height: 1;
            text-indent: -9999px;
            background: {
                repeat: no-repeat;
                size: cover;
            }
        }
    }

    .srr-facebook a {
        background-image: url(/img/social/facebook.svg);
    }

    .srr-twitter a {
        background-image: url(/img/social/twitter.svg);
    }

    .srr-instagram a {
        background-image: url(/img/social/instagram.svg);
    }

    .srr-details .srr-page & {
        .srr-link {
            margin-right: 0.5rem;
        }

        .srr-link-icon {
            width: 1.6875rem;
            height: 1.6875rem;

            a {
                border-radius: 0.2rem;
            }
        }
    }
}

.srr-page {
    .srr-page-title {
        font-size: 1.5rem;
        line-height: 1.375;
    }
    .srr-page-location {
        margin-bottom: 0.25rem;
        color: #666;
        font-size: 0.875rem;
        line-height: 1.375;

        & + .srr-links {
            margin-top: 0.625rem;
        }
    }
    .srr-links {
        margin: 0.25rem 0;
    }
}

.srr-form-set-description {
    margin-top: -0.5rem;
}

.srr-form-set-button-bar {
    margin-bottom: 1rem;
}

.srr-search {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.05);
}

.srr-twidget {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;

    iframe {
        display: block !important;
    }
}

.srr-footer {
    .srr-footer-links {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 2rem 0;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;

        @media (min-width: 720px) {
            @include clearfix();
        }
    }

    .srr-gc {
        @media (min-width: 720px) {
            float: left;
            text-align: left;
        }

        a {
            display: inline-block;
            padding-left: 1.375rem;
            color: #000;
            background: {
                image: url(/img/gc.svg);
                repeat: no-repeat;
                size: 1rem;
            }
        }
    }

    .srr-terms {
        margin-top: 1rem;

        @media (min-width: 720px) {
            float: right;
            margin-top: 0;
            text-align: right;
        }

        a {
            display: inline-block;
            color: #999;
        }

        a + a {
            margin-left: 1rem;
        }
    }
}

.srr-tabnav {
    margin-bottom: 2rem;
}

.srr-search-thing {
    position: relative;
}

.srr-livesearch {
    position: absolute;
    width: 100%;
    z-index: 1;
}

.srr-backlink {
    margin: -1rem 0 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 1rem;
}

.srr-profile {
    margin-bottom: 2rem;

    .srr-name {
        margin-bottom: 0;
        border-bottom-width: 0;
        font-size: 1.5rem;
        text-align: center;
    }

    .srr-empty-profile {
        margin: 2rem 0;
        text-align: center;
    }
}

.srr-form-header {
    margin-bottom: 1rem;
    font-size: 2rem;
}

.srr-alert {
    margin-bottom: 2rem;
}

.srr-field-bundle {
    margin-bottom: 1rem;
    padding: 0.5rem 0 0.25rem;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}

.srr-field-bundle-title {
    margin-bottom: 0.5rem;
}

.srr-checkbox {
    @include clearfix();
    margin-bottom: 0.25rem;

    label {
        margin-bottom: 0;
    }

    .srr-checkbox-label {
        display: block;
        float: left;
        padding: 4px 0;
    }

    .srr-checkbox-field {
        display: block;
        float: right;
    }
}

.srr-pagination {
    margin: 0 0 2rem;
}

#srr-form-logout {
    display: none;
}
